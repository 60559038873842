import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import { FaReact } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import * as Styled from './Navigation.styles';

const iconStyle = { verticalAlign: 'middle', marginRight: '0.5rem' };

const ReactIcon = () => <FaReact color="#519aba" style={iconStyle} />;

const ArrowIcon = ({ direction }: { direction: 'down' | 'up' }) => {
  switch (direction) {
    case 'down':
      return <IoIosArrowDown style={iconStyle} />;
    case 'up':
      return <IoIosArrowUp style={iconStyle} />;
    default:
      return null;
  }
};

type NavLink = {
  sort: number;
  navigation: { text: string; link: string };
};

const NavList = ({ isOpen, links }: { isOpen: boolean; links: NavLink[] }) => (
  <Styled.NavList isOpen={isOpen}>
    {links
      .sort((a, b) => a.sort - b.sort)
      .map(({ navigation: { link, text } }) => (
        <Styled.ListItem key={link}>
          <Link to={link}>
            <ReactIcon />
            {text}
          </Link>
        </Styled.ListItem>
      ))}
  </Styled.NavList>
);

export const Navigation = ({ links }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Styled.Navigation>
      <Styled.Top>
        <ArrowIcon direction="down" />
        pages
      </Styled.Top>

      <NavList isOpen={isOpen} links={links} />

      <Styled.MobileToggleButton onClick={() => setIsOpen(v => !v)}>
        <ArrowIcon direction={isOpen ? 'down' : 'up'} />
        pages
      </Styled.MobileToggleButton>
    </Styled.Navigation>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { glob: "*-page" } } }
        ) {
          edges {
            node {
              frontmatter {
                sort
                navigation {
                  text
                  link
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Navigation
        links={data.allMarkdownRemark.edges.map(
          (edge: {
            node: {
              frontmatter: NavLink;
            };
          }) => edge.node.frontmatter,
        )}
      />
    )}
  />
);
