import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Navigation from '../components/Navigation';
import { mediaQuery } from '../styles/utils';

const Container = styled.div`
  display: flex;
`;

const Main = styled.main`
  padding: 2rem;
  max-width: 80ch;

  ${mediaQuery.md`
    padding-left: 26rem;
    max-width: calc(90ch + 26rem);
  `}
`;

export default ({ children }) => {
  return (
    <Container>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Fira+Code&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>

      <Navigation />

      <Main>{children}</Main>
    </Container>
  );
};
