import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export const Navigation = styled.nav`
  background-color: rgb(42, 46, 54);
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;

  ${mediaQuery.md`
    height: 100vh;
    width: 24rem;
  `}
`;

export const NavList = styled.ul<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 2rem;

  ${mediaQuery.md`
    display: block;
  `}
`;

export const ListItem = styled.li`
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${mediaQuery.md`
    margin-bottom: 1rem;
  `}
`;

export const Top = styled.div`
  display: none;

  ${mediaQuery.md`
    display: block;
    padding: 2rem 2rem 0 2rem;
  `}
`;

export const MobileToggleButton = styled.button`
  display: block;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 2rem;

  ${mediaQuery.md`
    display: none;
  `}
`;
