// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-impressum-page-tsx": () => import("./../../../src/templates/impressum-page.tsx" /* webpackChunkName: "component---src-templates-impressum-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-portfolio-page-tsx": () => import("./../../../src/templates/portfolio-page.tsx" /* webpackChunkName: "component---src-templates-portfolio-page-tsx" */)
}

