import { css } from 'styled-components';

export const sizes = {
  md: 768,
  lg: 1200,
};

export const mediaQuery = Object.keys(sizes).reduce<{ md?: any; lg?: any }>(
  (acc, label) => {
    acc[label] = (...args: any) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...(args as [any, any, any]))};
      }
    `;
    return acc;
  },
  {},
);
